import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import { graphql } from 'gatsby';
import Image from '@components/Image/Image';
export const query = graphql`
  query {
    ivbssImage: file(relativePath: { eq: "media/volpe/ivbss2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 793)
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Volpe Center`}</h1>
    <p><em parentName="p">{`Engineer, Crash Avoidance and Vehicle Safety`}</em></p>
    <p><em parentName="p">{`Volpe National Transportation Systems Center, Research and Innovative Technology Administration, US DOT`}</em></p>
    <p>{`Worked with a small team that evaluated the effectiveness of in-vehicle-based safety systems for studies sponsored by the US Department of Transportation. These safety systems included: lane change, merge, and lane departure warnings (lateral movement into a clear or occupied lane, using side radar), forward collision warnings (approaching car ahead too fast, using front radar), and curve speed warnings (going into a curve too fast, using GPS to check against known road geometry).`}</p>
    <p>{`Using gigabytes of video and vehicle movement data collected from a fleet of cars and trucks outfitted with sensors and cameras, we developed algorithms to identify near-collisions (and saw some actual ones) and helped classify them based on observed distractions. Had personal responsibility for developing Excel sheets to automate data analysis as well as a widely used desktop application in C# .NET to match up 2/5/10 Hz vehicle data with five different angles of recorded video (having different frame rates).`}</p>
    <br />
    <Image alt="" image={props.data.ivbssImage.childImageSharp.gatsbyImageData} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      